<template>
  <div class="d-flex align-md-center flex-md-row flex-column my-5">
    <h1 class="font-weight-medium">
      {{ greeting }}
    </h1>
    <div>
    <v-chip color="backgroundDarkest" class="primary--text ml-md-3">
      Family & Friends
    </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
  },
  computed: {
    time() {
      const date = new Date();

      const currentTime = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      if (currentTime >= '00:00' && currentTime < '06:00') {
        return 'night';
      }
      if (currentTime >= '06:00' && currentTime < '12:00') {
        return 'morning';
      }
      if (currentTime >= '12:00' && currentTime < '17:00') {
        return 'afternoon';
      }
      if (currentTime >= '17:00' && currentTime <= '23:59') {
        return 'evening';
      }
      return 'whatTime';
    },
    greeting() {
      /* eslint-disable prefer-template */
      return this.name
        ? `${this.$t('components.shared.greeting')}, ${this.name}`
        : this.$t('components.shared.greeting');
    },
  },
};
</script>
